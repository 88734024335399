<template>
  <div id="app">
    <div class="left">
      <success-alert
        :success="saveResetPassword_Success"
        class="font-weight-black white--text ml-2 mt-2"
      >
        <template #success-message>
          Your password is successfully reset. Go to login to continue.
        </template>
      </success-alert>
      <error-alert
        :error="error"
        :error-message="errorMessage"
        class="ml-2 mt-2"
        @error-closed="error=false"
      ></error-alert>
      <div class="content">
        <img src="@/assets/logoHor.png"/>
        <!-- <h1 class="white--text pt-10 pb-16 logo">RiverTemp</h1> -->
        <h2>Reset Password</h2>
        <v-form
          class="form"
          @submit.prevent="setNewPassword"
        >
          <password-field
            :dark="true"
            @password-input="password = $event"
            @password-valid = "validPassword = $event"
          >
            <template #password-label>New Password</template>
          </password-field>
          <confirm-password-field
            :password="password"
            @confirmed-password-input="confirmedPassword = $event"
            @confirmed-password-valid = "validConfirmedPassword = $event"
          >
            <template #confirm-password-label>Confirm New Password</template>
          </confirm-password-field>
          <div class="form-element form-submit">
            <v-btn 
              id="signUp" 
              class="signup signup-btn mt-2 text-capitalize" 
              type="submit" 
              name="signup" 
              :disabled="invalidForm" 
            >
              Reset Password
            </v-btn>
            <v-btn
              id="goLeft" 
              color="transparent"
              class="signup off text-capitalize mt-2" 
              @click="$router.push('/login')"
            >
              Go to Login Page
            </v-btn> 
            </div>
        </v-form>
      </div>
    </div>
    <img src="@/assets/reset.jpg" class="reset-img"/>
  </div>
</template>
  
<script>
  import { withAsync } from "@/helpers/withAsync";
  import { apiStatus } from "@/api/constants/apiStatus";
  import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
  import { apiObject } from "@/api/api";
  import { saveResetPassword } from "@/api/authenticationApi.js";
  import ErrorAlert from "@/components/ErrorAlert.vue";
  import SuccessAlert from "@/components/SuccessAlert.vue";
  import PasswordField from "@/components/PasswordField.vue";
  import ConfirmPasswordField from "@/components/ConfirmPasswordField.vue";

  export default {
    components: { ErrorAlert, SuccessAlert, PasswordField, ConfirmPasswordField },

    name: "SignUser",
    data() {
      return {
        errorMessage: "",
        error: false,
        password: null,
        confirmedPassword: null,
        validPassword: null,
        validConfirmedPassword: null,
        saveResetPassword: apiStatus.Idle,
      };
    },

    computed:{

      invalidForm() {
        return !(this.password !== null && this.confirmedPassword !== null && this.validPassword === true && this.validConfirmedPassword === true);
      },

      ...apiStatusComputed("saveResetPassword")
    },
    methods: {
     
      async setNewPassword() {
        // Get some payload info from the url
        const payload = {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.password,
          password_confirmation: this.confirmedPassword
        }

        this.saveResetPassword =        apiStatus.Pending;

        const {response, error} =       await withAsync(saveResetPassword, payload);

        if(error){       
          console.log(error)         
          this.saveResetPassword =      apiStatus.Error;
          this.error =                  true;
         
          this.errorMessage = error.response.data.metadata.response_message;
         
          return this.errorMessage;
        }

        this.saveResetPassword =        apiStatus.Success;
        // setTimeout(() => {
        //   this.$router.push("/login");
        // }, 5000);
        let apiResponse = response.data.data;   
      },
    },
  };
</script>
  
<style scoped lang="scss">
  @import "@/plugins/login.scss";
  .project-login {
    color: #19a7ce;
  }
  .form-label {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
  }
  .reset-img {
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 0;
  }
  .logo {
    font-family: "Rubik", sans-serif;
    font-size: 80px;
  }
  
  .signup-btn {
    color: #19a7ce !important;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(0 0 0 / 15%)!important;
    color: #ffffffdb !important;
  }
  .form{
    height: 50%;
  }
  .left{
    height: 100% !important;
  }

  @media only screen and (max-width:860px){
    .reset-img{
      height: 180vh !important;
    }
    .left{
      height: 180vh !important;
    }
  }
</style>
  